import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Button } from "@sablier/v2-components/molecules";
import { DEFAULT_CHAIN_ID } from "@sablier/v2-constants";
import { useChainData } from "@sablier/v2-hooks";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Stream } from "@sablier/v2-models";
import { vendors } from "@sablier/v2-utils";
import { Characteristic } from "~/client/components/molecules";
import { tabs } from "~/client/constants";
import {
  useModalStreamSearch,
  useStreamDashboardSearch,
  useStreamDashboardTab,
  useToken,
} from "~/client/hooks";
import type { IFilterStream } from "@sablier/v2-models";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    width: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 1);
    border: 2px solid ${(props) => props.theme.colors.border};
    border-bottom: none;
    border-top-right-radius: 8px;
    background-color: ${(props) => props.theme.colors.dark150};
  }
`;

const Summary = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    padding-left: 4px;
    border-radius: 4px;
    p {
      color: ${(props) => props.theme.colors.gray200} !important;
      font-weight: 500 !important;
      line-height: 1.4 !important;
    }
  }
`;

const Actions = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-wrap: wrap;
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    margin-left: auto;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    ${(props) => props.theme.styles.column}
    ${Actions} {
      margin-left: 0;
    }
  }
  ${(props) => props.theme.medias.maxMD} {
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    border-radius: 0;

    ${Actions} {
      column-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    ${(props) => props.theme.styles.column}
    & {
      gap: calc(${(props) => props.theme.sizes.edge} * 1);
      height: auto;
      padding: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      padding-top: calc(${(props) => props.theme.sizes.edge} * 1);
      border-radius: 0;

      ${Summary} {
        padding-left: 2px;
      }
      ${Actions} {
        width: 100%;
        & > * {
          flex: 1;
        }
      }
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2) !important;

    ${Actions} {
      & > * {
        flex: auto;
      }
    }
  }
`;

function Conditional() {
  const { identifier, isReady } = useStreamDashboardTab();

  if (!isReady || identifier !== tabs.dashboard.search.identifier) {
    return false;
  }

  return <Filter />;
}

function Filter() {
  const searched = useStreamDashboardSearch();
  const { setOpen: setOpenSearch } = useModalStreamSearch();
  const { t } = useT();

  const filter: IFilterStream = useMemo(
    () => Stream.doFormatFilter(searched.result?.filter || {}),
    [searched.result?.filter],
  );
  const found = useMemo(
    () => searched.result?.streams.length || 0,
    [searched.result?.streams.length],
  );

  const ids = useMemo(() => filter.streamIds?.length || 0, [filter]);
  const { chain } = useChainData(filter.chainId || DEFAULT_CHAIN_ID);
  const token = useToken({ address: filter.token, chainId: chain?.chainId });

  const doOpenSearch = useCallback(() => {
    vendors.track.log((events) => {
      return events.openModalFrom({
        nameKey: "search",
        placeKey: "dashboard",
      });
    });
    setOpenSearch(true, {
      filter,
    });
  }, [filter, setOpenSearch]);

  if (searched.isLoading) {
    return false;
  }

  return (
    <Wrapper data-component={"filter"}>
      <Summary>
        <Characteristic.FilterStream
          chain={chain}
          sender={filter.sender}
          recipient={filter.recipient}
          token={token}
          ids={ids}
          found={found}
        />
      </Summary>
      <Actions>
        <Button
          accent={"iconic400"}
          appearance={"outline"}
          title={_.capitalize(t("words.search"))}
          titleMedium={_.capitalize(t("words.search"))}
          left={MagnifyingGlassIcon}
          onClick={doOpenSearch}
          isMini
        />
      </Actions>
    </Wrapper>
  );
}

export default Conditional;
