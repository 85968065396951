
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Dashboard from "../src/pages/Stream/Dashboard";
export default Dashboard;

    async function __Next_Translate__getStaticProps__19200e2c1e5__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19200e2c1e5__ as getStaticProps }
  