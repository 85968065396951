import { useCallback } from "react";
import styled from "styled-components";
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  Bars3Icon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Tab } from "@sablier/v2-components/molecules";
import { tags } from "@sablier/v2-constants";
import { vendors } from "@sablier/v2-utils";
import { tabs } from "~/client/constants";
import {
  useChain,
  useModalStreamSearch,
  useStreamDashboardQuery,
  useStreamDashboardTab,
  useStreamDashboardWatcher,
} from "~/client/hooks";

const dashboard = tabs.dashboard;

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex: 1;
    margin-bottom: -2px;
    & > *:not(:first-child) {
      margin-left: -12px;
    }
    & > *:nth-child(1) {
      z-index: 10;
    }
    & > *:nth-child(2) {
      z-index: 9;
    }
    & > *:nth-child(3) {
      z-index: 8;
    }
    & > *:nth-child(4) {
      z-index: 7;
    }

    & > * {
      &[data-active="true"] {
        --color: ${(props) => props.theme.colors.white} !important;
        --background: ${(props) => props.theme.colors.dark250} !important;
        border-top-left-radius: 6px;

        overflow: hidden;

        & > * {
          border-left-color: ${(props) =>
            props.theme.colors.primaryMiddle} !important;
        }

        & > div > *:first-child {
          background: ${(props) => props.theme.gradients.primary};
          box-shadow: -60px 0px 40px 10px
            ${(props) => props.theme.colors.primaryMiddle};
        }
      }
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    & > * {
      &:not(:first-child) {
        margin-left: -2px !important;
      }
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    width: 100%;
    & > * {
      z-index: ${(props) => props.theme.sizes.zIndexOver} !important;
      flex: 1;
      &:not(:first-child) {
        border-top-left-radius: 0px;
      }
    }
  }
`;

function Tabs() {
  useStreamDashboardWatcher(true);

  const { chain } = useChain();
  const { tab } = useStreamDashboardTab();
  const { filter } = useStreamDashboardQuery();
  const { setOpen } = useModalStreamSearch();

  const doOpenSearch = useCallback(() => {
    vendors.track.log((events) => {
      return events.openModalFrom({
        nameKey: "search",
        placeKey: "tab",
      });
    });
    setOpen(true, {
      filter: {
        ...filter,
        chainId: filter?.chainId || chain?.chainId,
      },
    });
  }, [filter, setOpen, chain]);

  return (
    <Wrapper>
      <Tab
        icon={Bars3Icon}
        isActive={tab?.identifier === dashboard.all.identifier}
        title={dashboard.all.title}
        titleShort={dashboard.all.titleShort}
        onClick={() => {
          vendors.track.log((events) => events.clickOnTab("all"));
        }}
        to={dashboard.all.builder()}
        cy={tags.tab}
      />
      <Tab
        icon={ArrowDownCircleIcon}
        isActive={tab?.identifier === dashboard.recipient.identifier}
        title={dashboard.recipient.title}
        titleShort={dashboard.recipient.titleShort}
        onClick={() => {
          vendors.track.log((events) => events.clickOnTab("recipient"));
        }}
        to={dashboard.recipient.builder()}
        cy={tags.tab}
      />
      <Tab
        icon={ArrowUpCircleIcon}
        isActive={tab?.identifier === dashboard.sender.identifier}
        title={dashboard.sender.title}
        titleShort={dashboard.sender.titleShort}
        onClick={() => {
          vendors.track.log((events) => events.clickOnTab("sender"));
        }}
        to={dashboard.sender.builder()}
        cy={tags.tab}
      />
      <Tab
        icon={MagnifyingGlassIcon}
        isActive={tab?.identifier === dashboard.search.identifier}
        title={dashboard.search.title}
        titleShort={dashboard.search.titleShort}
        onClick={doOpenSearch}
        cy={tags.tab}
      />
    </Wrapper>
  );
}

export default Tabs;
