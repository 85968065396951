import styled from "styled-components";
import { Table } from "@sablier/v2-components/organisms";
import { useStreamDashboardTable } from "~/client/hooks";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    --dashboard-column-1: 160px;
    --dashboard-column-2: minmax(260px, max-content);
    --dashboard-column-3: minmax(0px, 1fr);
    --dashboard-column-4: minmax(160px, 1fr);
    --dashboard-column-5: minmax(200px, max-content);
    --dashboard-column-6: minmax(140px, max-content);
    width: 100%;
  }

  ${(props) => props.theme.medias.minLG} {
    *[data-component="table"] {
      *[data-component="body"] {
        *[data-component="row"] {
          & > *:nth-child(1) {
            *[data-component="label"] {
              ${(props) => props.theme.styles.ellipsisAdaptive}
            }
          }
          & > *:nth-child(2) {
            & > div > div:not([data-component="label"]) {
              ${(props) => props.theme.styles.ellipsisAdaptive}
              & {
                width: auto;
              }
            }
          }
        }
      }
    }
  }

  ${(props) => props.theme.medias.maxXL} {
    --dashboard-column-1: 130px;
    --dashboard-column-2: minmax(190px, max-content);
    --dashboard-column-3: minmax(0px, 1fr);
    --dashboard-column-4: minmax(160px, max-content);
  }

  ${(props) => props.theme.medias.maxContainer} {
    *[data-component="table"] {
      *[data-component="header"] {
        grid-gap: 0;
        padding-inline: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      }
      *[data-component="body"] {
        *[data-component="row"] {
          grid-gap: 0;
          padding-inline: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
        }
      }
    }
  }

  ${(props) => props.theme.medias.max(props.theme.sizes.deviceLGBreak)} {
    *[data-component="table"] {
      *[data-component="header"] {
        display: none;
      }
      *[data-component="body"] {
        padding: calc(${(props) => props.theme.sizes.edge} * 1 / 2 - 2px);
        border: 2px solid ${(props) => props.theme.colors.border};
        background-color: ${(props) => props.theme.colors.dark100};
        & > *[data-component="list"] {
          grid-template-columns: 1fr 1fr;
          grid-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2 - 2px);

          *[data-component="row"] {
            grid-column: span 1;
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: calc(${(props) => props.theme.sizes.edge} * 1);
            height: auto;
            padding-inline: calc(${(props) => props.theme.sizes.edge} * 1);
            padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
            border: 2px solid ${(props) => props.theme.colors.border};
            border-radius: 6px;

            &:hover,
            &:active,
            &:focus {
              background-color: ${(props) => props.theme.colors.dark200};
            }

            & > * {
              grid-column: span 1;
              width: 100%;
              height: auto;
              padding-inline: calc(${(props) => props.theme.sizes.edge} * 0);
            }

            & > *:nth-child(1) {
              ${(props) => props.theme.styles.row}
              & {
                justify-content: flex-start;
                height: auto;
                padding-top: calc(${(props) => props.theme.sizes.edge} * 1);
                padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
                border-bottom: 2px solid ${(props) => props.theme.colors.border};
              }
            }

            & > *:nth-child(2),
            & > :nth-child(4) {
              & > * {
                gap: calc(${(props) => props.theme.sizes.edge} * 1);
              }
            }

            & > *:nth-child(3) {
              ${(props) => props.theme.styles.row}
              & {
                position: absolute;
                top: calc(${(props) => props.theme.sizes.edge} * 2);
                right: calc(${(props) => props.theme.sizes.edge} * 2);
                justify-content: flex-end;
                align-items: center;
                padding: 0;
                & > * {
                  transform: scale(1.2);
                }
                *[data-component="label"] {
                  display: none;
                }
              }
            }

            & > *:nth-child(5) {
              ${(props) => props.theme.styles.row}
              & {
                width: 100%;
                min-height: 40px;
                & > * {
                  width: calc(100% - 54px);
                  & > * {
                    width: 100%;
                    max-width: 100%;
                  }
                }
              }
            }

            & > *:nth-child(6) {
              ${(props) => props.theme.styles.row}
              & {
                position: absolute;
                bottom: calc(${(props) => props.theme.sizes.edge} * 1);
                right: calc(${(props) => props.theme.sizes.edge} * 1);
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                min-height: 40px;
                padding: 0;
                & > * {
                  & > *:first-child {
                    grid-gap: calc(
                      1 / 3 * ${(props) => props.theme.sizes.edge}
                    );
                    & > *:first-child {
                      display: none;
                    }
                  }
                  *[data-component="indicator"] {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    *[data-component="table"] {
      *[data-component="body"] {
        & > *[data-component="list"] {
          grid-template-columns: 1fr;
          *[data-component="row"] {
            padding-inline: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
            padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);

            & > *:nth-child(3) {
              right: calc(${(props) => props.theme.sizes.edge} * 1);
            }

            & > *:nth-child(6) {
              bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
              right: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
            }
          }
        }
      }
    }
  }

  ${(props) => props.theme.medias.betweenSMAndMD} {
    *[data-component="table"] {
      *[data-component="body"] {
        & > *[data-component="list"] {
          *[data-component="row"] {
            & > *:nth-child(1) {
              max-width: 140px;
            }
          }
        }
      }
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    *[data-component="table"] {
      *[data-component="body"] {
        & > *[data-component="list"] {
          *[data-component="row"] {
            & > *:nth-child(1) {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
`;

function Body() {
  const data = useStreamDashboardTable();

  return (
    <Wrapper>
      <Table data={data} />
    </Wrapper>
  );
}

export default Body;
