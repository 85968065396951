import { useMemo } from "react";
import styled from "styled-components";
import { Chain, Header } from "@sablier/v2-components/molecules";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { tabs } from "~/client/constants";
import { useStreamDashboardQuery, useStreamDashboardTab } from "~/client/hooks";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    width: 100%;
    padding-top: calc(${(props) => props.theme.sizes.edge} * 0);
  }
`;

function Navigation() {
  const { t } = useT();
  const { tab } = useStreamDashboardTab();
  const { filter, isReady } = useStreamDashboardQuery();

  const title = useMemo(() => {
    switch (tab?.identifier) {
      case tabs.dashboard.search.identifier:
        return isReady && filter.chainId ? (
          <>
            {_.startCase(t("structs.filteredStreams"))} on{" "}
            <Chain id={filter.chainId} isNameShown={false} size={32} />
          </>
        ) : (
          _.startCase(t("structs.filteredStreams"))
        );

      case tabs.dashboard.recipient.identifier:
        return _.startCase(t("structs.incomingStreams"));
      case tabs.dashboard.sender.identifier:
        return _.startCase(t("structs.outgoingStreams"));
      case tabs.dashboard.all.identifier:
      default:
        return _.startCase(t("structs.allStreams"));
    }
  }, [filter, isReady, tab, t]);

  return (
    <Wrapper>
      <Header title={title} titleShort={_.capitalize(t("words.streams"))} />
    </Wrapper>
  );
}

export default Navigation;
